import { MatPaginator } from '@angular/material/paginator';

export function localizePaginatorLabels(paginator: MatPaginator) {
  paginator._intl.itemsPerPageLabel = 'Einträge pro Seite';
  paginator._intl.nextPageLabel = 'Nächste Seite';
  paginator._intl.previousPageLabel = 'Vorherige Seite';
  paginator._intl.firstPageLabel = 'Erste Seite';
  paginator._intl.lastPageLabel = 'Letzte Seite';
  paginator._intl.getRangeLabel = (
    page: number,
    pageSize: number,
    length: number
  ) => {
    if (length === 0 || pageSize === 0) {
      return `0 von ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} von ${length}`;
  };
}
